import React from 'react';

const Hero = () => {
  return (
    <section className="bg-gradient-to-b from-gray-900 via-black to-gray-800 text-white min-h-screen flex flex-col items-center justify-center px-6 md:px-16">
      {/* Hero Content */}
      <div className="text-center space-y-6">
        {/* Headline */}
        <h1 className="text-4xl md:text-6xl font-extrabold leading-tight tracking-wide">
          Revolutionize <span className="text-orange-500">Education</span> with 
          <br /> CampusHub360
        </h1>

        {/* Subtitle */}
        <p className="text-lg md:text-xl text-gray-400 max-w-2xl mx-auto">
          A unified platform designed to streamline college management for students, faculty, and administrators. Stay connected, stay efficient, and stay ahead.
        </p>

        {/* Call to Action */}
        <div className="space-x-4">
          <button className="bg-orange-500 text-white px-6 py-3 rounded-lg shadow-lg hover:bg-orange-600 transition">
            Get Started
          </button>
          <button className="border-2 border-orange-500 text-orange-500 px-6 py-3 rounded-lg hover:bg-orange-500 hover:text-white transition">
            Learn More
          </button>
        </div>
      </div>

      {/* Hero Image */}
      {/* <div className="mt-10 md:mt-16">
        <img
          src="/hero-image.svg" 
          alt="Hero Illustration"
          className="w-full max-w-4xl mx-auto animate-fadeIn"
        />
      </div> */}

      {/* Key Features Section */}
      <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-6 text-center">
        <div className="bg-gray-800 p-6 rounded-lg hover:shadow-xl transition">
          <h3 className="text-xl font-semibold text-orange-500">Easy Enrollment</h3>
          <p className="text-gray-400">
            Simplify student admissions and registrations.
          </p>
        </div>
        <div className="bg-gray-800 p-6 rounded-lg hover:shadow-xl transition">
          <h3 className="text-xl font-semibold text-orange-500">Attendance Tracking</h3>
          <p className="text-gray-400">
            Automate attendance and stay informed.
          </p>
        </div>
        <div className="bg-gray-800 p-6 rounded-lg hover:shadow-xl transition">
          <h3 className="text-xl font-semibold text-orange-500">Grade Reporting</h3>
          <p className="text-gray-400">
            Provide students with a seamless grading system.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Hero;
