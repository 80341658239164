import React, { useState } from "react";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="bg-gradient-to-r from-gray-900 via-black to-gray-900 text-white sticky top-0 z-50 shadow-lg">
      <div className="container mx-auto flex justify-between items-center p-4">
        {/* Logo Section */}
        <div className="flex items-center space-x-2">
          <h1 className="text-2xl font-bold tracking-wider">CampusHub360</h1>
        </div>

        {/* Navigation Links */}
        <nav className="hidden md:flex space-x-6">
          <a href="#features" className="hover:text-orange-500 transition">
            Features
          </a>
          <a href="#about" className="hover:text-orange-500 transition">
            About Us
          </a>
          <a href="#contact" className="hover:text-orange-500 transition">
            Contact
          </a>
        </nav>

        {/* Action Buttons */}
        <div className="hidden md:flex space-x-4">
          <a
            href="https://student.campushub360.xyz/"
            className="bg-orange-500 px-4 py-2 rounded hover:bg-orange-600 transition"
          >
            Login as Student
          </a>
          <a
            href="https://faculty.campushub360.xyz/"
            className="bg-orange-500 px-4 py-2 rounded hover:bg-orange-600 transition"
          >
            Login as Faculty
          </a>
          <a
            href="https://admin.campushub360.xyz/"
            className="bg-orange-500 px-4 py-2 rounded hover:bg-orange-600 transition"
          >
            Login as Admin
          </a>
        </div>

        {/* Mobile Menu Button */}
        <div className="md:hidden flex items-center">
          <button
            className="text-orange-500 focus:outline-none"
            aria-label="Toggle Menu"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
              />
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div
          className="bg-gray-800 md:hidden flex flex-col items-center space-y-4 py-4 transition-transform duration-300"
          onClick={() => setIsMenuOpen(false)} // Close menu when a link is clicked
        >
          <a href="#features" className="text-white hover:text-orange-500 transition">
            Features
          </a>
          <a href="#about" className="text-white hover:text-orange-500 transition">
            About Us
          </a>
          <a href="#contact" className="text-white hover:text-orange-500 transition">
            Contact
          </a>
          <a
            href="https://student.campushub360.xyz/"
            className="bg-orange-500 px-4 py-2 rounded hover:bg-orange-600 transition"
          >
            Login as Student
          </a>
          <a
            href="https://faculty.campushub360.xyz/"
            className="bg-orange-500 px-4 py-2 rounded hover:bg-orange-600 transition"
          >
            Login as Faculty
          </a>
          <a
            href="https://admin.campushub360.xyz/"
            className="bg-orange-500 px-4 py-2 rounded hover:bg-orange-600 transition"
          >
            Login as Admin
          </a>
        </div>
      )}
    </header>
  );
};

export default Header;
