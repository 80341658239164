import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-gray-400 py-16">
      <div className="container mx-auto px-6 md:px-16">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          {/* About Section */}
          <div>
            <h3 className="text-xl font-bold text-orange-500 mb-4">About CampusHub360</h3>
            <p>
              CampusHub360 is the ultimate college management platform, designed
              to bring students, faculty, and administrators together on one
              seamless platform. Experience efficiency and collaboration like
              never before.
            </p>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-xl font-bold text-orange-500 mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li>
                <a href="#features" className="hover:text-white transition">
                  Features
                </a>
              </li>
              <li>
                <a href="#about" className="hover:text-white transition">
                  About Us
                </a>
              </li>
              <li>
                <a href="#contact" className="hover:text-white transition">
                  Contact Us
                </a>
              </li>
              <li>
                <a href="#blog" className="hover:text-white transition">
                  Blog
                </a>
              </li>
            </ul>
          </div>
{/* 
          <div>
            <h3 className="text-xl font-bold text-orange-500 mb-4">Contact Us</h3>
            <p className="mb-4">
              <strong>Address:</strong> 123 College Ave, Education City, India
            </p>
            <p className="mb-4">
              <strong>Email:</strong>{" "}
              <a
                href="mailto:support@campushub360.com"
                className="text-orange-500 hover:underline"
              >
                support@campushub360.com
              </a>
            </p>
            <p className="mb-4">
              <strong>Phone:</strong>{" "}
              <a
                href="tel:+1234567890"
                className="text-orange-500 hover:underline"
              >
                +123 456 7890
              </a>
            </p>
            <div className="flex space-x-4 mt-6">
              <a href="#" aria-label="Facebook">
                <img src="/icons/facebook.svg" alt="Facebook" className="h-6 w-6" />
              </a>
              <a href="#" aria-label="Twitter">
                <img src="/icons/twitter.svg" alt="Twitter" className="h-6 w-6" />
              </a>
              <a href="#" aria-label="LinkedIn">
                <img src="/icons/linkedin.svg" alt="LinkedIn" className="h-6 w-6" />
              </a>
            </div>
          </div> */}
        </div>

        {/* Footer Bottom Section */}
        <div className="mt-12 border-t border-gray-700 pt-6 flex flex-col md:flex-row justify-between items-center text-sm">
          <p>© {new Date().getFullYear()} CampusHub360. All rights reserved.</p>
          <div className="flex space-x-4 mt-4 md:mt-0">
            <a href="#" className="hover:text-orange-500 transition">
              Privacy Policy
            </a>
            <a href="#" className="hover:text-orange-500 transition">
              Terms of Service
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
