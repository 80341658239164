import React from 'react';

const features = [
  {
    title: "Streamlined Enrollment",
    description: "Simplify the student registration process with automated workflows.",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="w-8 h-8 text-white"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M12 4v16m8-8H4"
        />
      </svg>
    ),
  },
  {
    title: "Attendance Tracking",
    description: "Monitor and manage attendance with real-time updates.",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="w-8 h-8 text-white"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M17 16l4-4m0 0l-4-4m4 4H7"
        />
      </svg>
    ),
  },
  {
    title: "Grade Management",
    description: "Provide a secure and seamless way to view and manage grades.",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="w-8 h-8 text-white"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M9 12h6m2 0a2 2 0 110-4h-8a2 2 0 100 4z"
        />
      </svg>
    ),
  },
  {
    title: "Faculty Profiles",
    description: "Manage faculty schedules, courses, and profiles effortlessly.",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="w-8 h-8 text-white"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M20 12H4"
        />
      </svg>
    ),
  },
  {
    title: "Communication Portal",
    description: "Enable real-time messaging for students, faculty, and administrators.",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="w-8 h-8 text-white"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M8 16h8m0 0v-4a4 4 0 00-8 0v4m0 0a4 4 0 01-8 0"
        />
      </svg>
    ),
  },
  {
    title: "Exam Room Allocation",
    description: "Automate exam room assignments for hassle-free examinations.",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="w-8 h-8 text-white"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M16 4v4m0 0V4m-8 16v-4m0 0V4m8 12h4m-4 0H4"
        />
      </svg>
    ),
  },
  {
    title: "Alumni Network",
    description: "Connect alumni with the institution and current students.",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="w-8 h-8 text-white"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M5 13l4 4L19 7"
        />
      </svg>
    ),
  },
  {
    title: "Event Management",
    description: "Organize and manage campus events seamlessly with automated tools.",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="w-8 h-8 text-white"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M12 8v8m0 0H8m4 0h4"
        />
      </svg>
    ),
  },
  {
    title: "Library Management",
    description: "Simplify library operations, including book lending and tracking.",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="w-8 h-8 text-white"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M12 4v16m0-16H8m4 0h4"
        />
      </svg>
    ),
  },
];

const Features = () => {
  return (
    <section id="features" className="bg-gradient-to-b from-black via-gray-900 to-gray-800 text-white py-16 px-6 md:px-16">
      <div className="text-center mb-12">
        <h2 className="text-4xl md:text-5xl font-extrabold mb-4 text-orange-500">
          Comprehensive Features
        </h2>
        <p className="text-lg md:text-xl text-gray-400 max-w-3xl mx-auto">
          CampusHub360 is packed with features to cater to every aspect of college management. From student engagement to alumni connections, we've got you covered.
        </p>
      </div>

      {/* Features Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {features.map((feature, index) => (
          <div
            key={index}
            className="bg-gray-800 p-6 rounded-lg shadow-lg hover:shadow-2xl transform hover:scale-105 transition duration-300"
          >
            {/* Icon */}
            <div className="flex items-center justify-center h-16 w-16 rounded-full bg-orange-500 mb-4 mx-auto">
              {feature.icon}
            </div>

            {/* Title */}
            <h3 className="text-2xl font-semibold text-orange-500 text-center mb-2">
              {feature.title}
            </h3>

            {/* Description */}
            <p className="text-gray-400 text-center">{feature.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Features;
