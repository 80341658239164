import React from 'react';

const ContactUs = () => {
  return (
    <section
      id="contact"
      className="bg-gradient-to-b from-black via-gray-900 to-gray-800 text-white py-16 px-6 md:px-16"
    >
      <div className="container mx-auto space-y-12">
        {/* Header Section */}
        <div className="text-center">
          <h2 className="text-4xl md:text-5xl font-extrabold text-orange-500">
            Contact Us
          </h2>
          <p className="text-lg md:text-xl text-gray-400 mt-4 max-w-3xl mx-auto">
            Have questions or need assistance? Reach out to us anytime, and
            we'll be happy to help.
          </p>
        </div>

        {/* Content Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          {/* Contact Form */}
          <div className="bg-gray-800 p-8 rounded-lg shadow-lg">
            <h3 className="text-3xl font-bold text-orange-500 mb-6">
              Get in Touch
            </h3>
            <form action="#" method="POST" className="space-y-6">
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-400"
                >
                  Your Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="mt-2 p-3 w-full bg-gray-700 rounded-lg text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-orange-500"
                  placeholder="Enter your name"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-400"
                >
                  Your Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="mt-2 p-3 w-full bg-gray-700 rounded-lg text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-orange-500"
                  placeholder="Enter your email"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="message"
                  className="block text-sm font-medium text-gray-400"
                >
                  Your Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows="5"
                  className="mt-2 p-3 w-full bg-gray-700 rounded-lg text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-orange-500"
                  placeholder="Write your message"
                  required
                ></textarea>
              </div>
              <button
                type="submit"
                className="w-full bg-orange-500 text-white py-3 rounded-lg font-semibold hover:bg-orange-600 transition"
              >
                Send Message
              </button>
            </form>
          </div>

          {/* Contact Details */}
          <div className="space-y-8">
            <div className="text-gray-400">
              <h3 className="text-3xl font-bold text-orange-500 mb-2">
                Contact Information
              </h3>
              <p className="mb-4">
                Feel free to reach out to us using any of the contact methods
                below.
              </p>
              <p>
                <strong>Address:</strong> Kadiri Road Angallu, Village, Madanapalle, Andhra Pradesh 517325, India
                India
              </p>
              <p>
                <strong>Email:</strong>
                <br />
                <a
                    href="mailto:t.chaitanya377@gmail.com"
                    className="text-orange-500 hover:underline"
                >
                    t.chaitanya377@gmail.com
                </a>
                <br />
                <a
                    href="mailto:gokultupakula@gmail.com"
                    className="text-orange-500 hover:underline"
                >
                    gokultupakula@gmail.com
                </a>
                </p>

              {/* <p>
                <strong>Phone:</strong>{" "}
                <a
                  href="tel:+1234567890"
                  className="text-orange-500 hover:underline"
                >
                  +123 456 7890
                </a>
              </p> */}
            </div>

            {/* Interactive Map */}
            <div>
              <h3 className="text-3xl font-bold text-orange-500 mb-4">
                Visit Us
              </h3>
              <iframe
                title="Campus Location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3877.4623694222782!2d78.47596377490515!3d13.629614786749615!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bb2677c83886ad7%3A0xad73159e2bddda33!2sMadanapalle%20institute%20of%20technology%20and%20Sciences!5e0!3m2!1sen!2sus!4v1733678481892!5m2!1sen!2sus"
                width="100%"
                height="300"
                frameBorder="0"
                allowFullScreen=""
                loading="lazy"
                className="rounded-lg shadow-lg"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
