import React from 'react';

const AboutUs = () => {
  return (
    <section
      id="about"
      className="bg-gradient-to-b from-gray-900 via-black to-gray-800 text-white py-16 px-6 md:px-16"
    >
      <div className="container mx-auto space-y-12">
        {/* Header Section */}
        <div className="text-center">
          <h2 className="text-4xl md:text-5xl font-extrabold text-orange-500">
            About Us
          </h2>
          <p className="text-lg md:text-xl text-gray-400 mt-4 max-w-3xl mx-auto">
            CampusHub360 is your ultimate college management solution. We bring
            students, faculty, and administrators together on one platform to
            create a smarter, more connected campus.
          </p>
        </div>

        {/* Content Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          {/* Image Section */}
          <div className="flex justify-center">
            <img
              src="/about us.jpg" // Replace with your actual illustration/image
              alt="About Us Illustration"
              className="w-full max-w-md md:max-w-lg"
            />
          </div>

          {/* Text Content */}
          <div className="space-y-6">
            <h3 className="text-3xl font-bold text-orange-500">
              Our Story
            </h3>
            <p className="text-gray-400">
              Founded with a vision to revolutionize educational technology,
              CampusHub360 is built to address the challenges of managing
              colleges in a digital-first world. From enrollment to alumni
              engagement, we streamline processes and foster a collaborative
              environment for all stakeholders.
            </p>

            <h3 className="text-3xl font-bold text-orange-500">
              Our Mission
            </h3>
            <p className="text-gray-400">
              To empower educational institutions with cutting-edge tools that
              simplify management, enhance communication, and create meaningful
              connections between students, faculty, and administrators.
            </p>

            <h3 className="text-3xl font-bold text-orange-500">
              Our Vision
            </h3>
            <p className="text-gray-400">
              A future where technology seamlessly integrates into education,
              enabling smarter campuses and better learning experiences for all.
            </p>
          </div>
        </div>

        {/* Values Section */}
        <div className="bg-gray-800 p-8 rounded-lg shadow-lg">
          <h3 className="text-3xl font-bold text-orange-500 text-center mb-6">
            Our Core Values
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 text-center">
            <div className="space-y-2">
              <div className="flex items-center justify-center h-16 w-16 bg-orange-500 rounded-full mx-auto">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8 text-white"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707a1 1 0 01-1.414 1.414L10 8.414 7.707 10.707a1 1 0 01-1.414-1.414l2.828-2.828a1 1 0 011.414 0l2.828 2.828z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <h4 className="text-xl font-semibold text-orange-500">
                Innovation
              </h4>
              <p className="text-gray-400">
                We embrace creativity and constantly push boundaries.
              </p>
            </div>
            <div className="space-y-2">
              <div className="flex items-center justify-center h-16 w-16 bg-orange-500 rounded-full mx-auto">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8 text-white"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.293 16.293a1 1 0 001.414 0L17 10l-1.414-1.414L10 14.586 4.707 9.293 3.293 10.707 9.293 16.293z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <h4 className="text-xl font-semibold text-orange-500">
                Collaboration
              </h4>
              <p className="text-gray-400">
                We foster teamwork and partnerships to achieve our goals.
              </p>
            </div>
            <div className="space-y-2">
              <div className="flex items-center justify-center h-16 w-16 bg-orange-500 rounded-full mx-auto">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8 text-white"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M6 8a4 4 0 018 0v3h1a3 3 0 110 6h-8a3 3 0 110-6h1V8z" />
                </svg>
              </div>
              <h4 className="text-xl font-semibold text-orange-500">
                Excellence
              </h4>
              <p className="text-gray-400">
                We strive for the highest standards in everything we do.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
